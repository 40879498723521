<template>
  <v-row justify="center">
    <!-- หน้าจอปกติ -->
    <v-dialog v-model="shower" width="650px" scrollable persistent v-if="resolutionScreen >= 500">
      <v-card>
        <v-card-title>{{$t("admin.password_expired")}}</v-card-title>          
        <v-divider></v-divider>
        <v-card-text class="pa-5">
          <!-- {{data}} -->
          <!-- {{listcount}} -->
          <v-layout justify-center align-center>
            <v-card-subtitle v-if="$t('default') === 'th'" :style="headerTable"> {{ $t("admin.business") }} : {{dataAccountActive.business_info.first_name_th}} </v-card-subtitle>
            <v-card-subtitle v-else :style="headerTable"> {{ $t("admin.business") }} : {{dataAccountActive.business_info.first_name_eng}} </v-card-subtitle>
            <v-list-item-action class="darkmode">
            </v-list-item-action>
          </v-layout>
          <v-layout row wrap justify-center class="mt-0pt-0">
                  <v-flex xs8 sm6 md4 lg4>
                    <v-text-field
                      solo
                      clearable
                      outlined
                      dense
                      class="pa-0 pt-0 pb-0"
                      v-model="day_expired"
                      :label="data.current_password_expired === '0' ? $t('admin.no_date_set') : $t('admin.no_date_set')"
                      prepend-icon="mdi-lock-reset"
                      :oninput="regexTaxid"
                      :maxlength="3"
                      :error-messages="dayExpiredErrors"
                      @input="$v.day_expired.$touch()"
                      @blur="$v.day_expired.$touch()"
                    ></v-text-field>
                  </v-flex>
                  <h3 class="mt-2 ml-3">{{$t("admin.day")}}</h3>
          </v-layout>
                      <span v-if="$t('default') === 'th'" :style="headerTable">ข้อมูลการแก้ไข : </span>
                      <span v-else :style="headerTable">Information Editing : </span>
                    <div class="mt-1">
                    <v-card>
                      <v-data-table
                        fixed-header
                        height="335px"
                        :headers="header"
                        :items="data.history_log"
                        class="elevation-0"
                        :no-data-text="$t('tablefile.empty_resend')"
                        :page.sync="page"
                        :items-per-page="size"
                        :hide-default-footer="true"
                        @page-count="pageCount = $event"
                      >
                       
                        <template v-slot:[`header.name`]="{ header }">
                          <span :style="headerTable">{{$t(header.text)}}</span>
                        </template>
                        <template v-slot:[`header.dtm`]="{ header }">
                          <span :style="headerTable">{{$t(header.text)}}</span>
                        </template>
                        <template v-slot:[`header.status`]="{ header }">
                          <span :style="headerTable">{{$t(header.text)}}</span>
                        </template>

                        <template v-slot:item="props">
                          <tr class="pointer" style="cursor:pointer;">
                            <td v-if="$t('default') === 'th'" class="text-center" width="33%">{{ props.item.name_th === null ? "ระบบ" : props.item.name_th}}</td>
                            <td v-else class="text-center" width="33%">{{ props.item.name_eng === null ? "System" : props.item.name_eng}}</td>
                            <td class="text-center" width="33%">{{ formatdatetime(props.item.last_dtm)}}</td>
                            <td class="text-center" width="33%">{{ props.item.password_expired === '0' ? $t('admin.no_date_set') : (props.item.password_expired + " " + $t("admin.day")) }}</td>
                          </tr>
                        </template>
                      </v-data-table>
                  </v-card>
                  <v-flex d-flex justify-center v-if="pageCount > 0">
                  <v-pagination
                    class="pt-1"
                    v-model="page"
                    :length="pageCount"
                    :color="color.theme"
                    :total-visible="10"
                    @click="paginatedData()"
                  ></v-pagination>
                  </v-flex>
                    </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            color="red" 
            outlined 
            @click="$emit('close'), clear()"
            >
          {{ $t("admin.close") }}
          </v-btn>
          <v-btn
            :color="color.theme"
            @click="setting_update_password()"
            class="white--text"
            :disabled="disabledsave"
          >
            {{ $t("admin.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- หน้าจอมือถือ -->
    <v-dialog 
      v-model="shower" 
      scrollable
      persistent
      :max-width="maxWidthOnMobile"
      content-class="rounded-lg"
      v-else >
      <v-card>
       <v-card-text class="pa-5" style="overflow-y: hidden;">
          <v-layout class="pt-2 pb-1">
            <v-flex d-flex justify-center class="ml-8">
              <span :style="headerPage">
                {{$t("admin.password_expired")}}
              </span>
            </v-flex>
            <!-- <v-flex xs1 d-flex justify-end>
              <v-icon
                :color="$vuetify.theme.dark ? 'white' : color.theme"
                @click="$emit('close'), clear()"
                >mdi-close</v-icon
              >
            </v-flex> -->
          </v-layout>
          <v-layout justify-center class="pt-3">
            <v-flex xs7 sm6 md4 lg4>
              <v-text-field
                solo
                clearable
                outlined
                dense
                class="pa-0 pt-0 pb-0"
                v-model="day_expired"
                :label="data.current_password_expired === '0' ? $t('admin.no_date_set') : $t('admin.no_date_set')"
                prepend-icon="mdi-lock-reset"
                :oninput="regexTaxid"
                :maxlength="3"
                :error-messages="dayExpiredErrors"
                @input="$v.day_expired.$touch()"
                @blur="$v.day_expired.$touch()"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout justify-left align-center>
            <span v-if="$t('default') === 'th'"> {{ $t("admin.business") }} : {{dataAccountActive.business_info.first_name_th}} </span>
            <span v-else> {{ $t("admin.business") }} : {{dataAccountActive.business_info.first_name_eng}} </span>
          </v-layout>
            <span v-if="$t('default') === 'th'">ข้อมูลการแก้ไข : </span>
            <span v-else>Information Editing : </span>
        </v-card-text>
        <v-card-text style="height: 400px;">
          
          <v-card>
                        <v-card-text>
                      <v-list dense two-line class="mb-0 pb-0 elevation-0" v-if="listcount !== 0" :style="styleDisplayMobile">
                        <v-list-item v-for="item in paginatedData" :key="item.title" style="cursor: pointer" :style="displayMobileForListItem">
                          <v-list-item-content>
                            <v-list-item-title v-if="$t('default') === 'th'" style="line-height:22px;" v-text="item.name_th"></v-list-item-title>
                            <v-list-item-title v-else style="line-height:22px;" v-text="item.name_eng"></v-list-item-title>
                            <v-layout>
                            <v-icon class="mr-1" size="16">mdi-lock-reset</v-icon>
                            <v-list-item-subtitle class="ml-1" style="line-height:22px;" v-text="item.password_expired === '0' ? $t('admin.no_date_set') : item.password_expired +' '+ $t('admin.day')"></v-list-item-subtitle>
                            </v-layout>
                           
                            <v-list-item-subtitle v-text="formatdatetime(item.last_dtm)"></v-list-item-subtitle>
                          </v-list-item-content>
                          
                        </v-list-item>
                      </v-list>
                      <v-list v-else class="mb-6 pb-6 elevation-0">
                        <v-list-item>
                          <v-list-item-content>
                            <v-img
                              :src="require('@/assets/img/icon_empty_data.png')"
                              max-width="132"
                              max-height="150"
                              class="mx-auto"
                            />
                            <v-list-item-title
                              :style="'font-size: 16px; font-weight: 700; line-height: 24px; color:' + color.theme + ';'"
                              class="text-center mt-8"
                              v-text="$t('tablefile.empty_resend')"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                        </v-card-text>
                      </v-card>
        </v-card-text>
        <div>
                      <v-flex d-flex justify-center v-if="pageCount > 0">
                      <v-pagination
                        class="pt-1"
                        v-model="page"
                        :length="pageCount"
                        :color="color.theme"
                        :total-visible="10"
                        @click="paginatedData()"
                      ></v-pagination>
                      </v-flex>
        <v-card-actions  style="justify-content: center;">
          <v-btn
            large
            class="mr-0" 
            color="red" 
            outlined 
            @click="$emit('close'), clear()"
          >
          {{ $t("admin.close") }}
          </v-btn>
          <v-btn
            large
            :color="color.theme"
            class="white--text"
            :disabled="disabledsave"
            @click="setting_update_password()"
          >
            {{ $t("admin.save") }}
          </v-btn>
        </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
    mixins: [validationMixin],
    validations: {
      day_expired: { required },
    },
    props: ["show","data","listcount"],
    components:{
    },
     data() {
    return {
      regexTaxid: "this.value = this.value.replace(/[^0-9]|/g, '').substr(0,13)",
      day_expired:'',
      size: 20,
      page: 1,
      header: [
        {
          text: "admin.name",
          align: "center",
          value: "name",
          width: "33%",
          sortable: false,
        },       
        {
          text: "admin.set_date",
          align: "center",
          value: "dtm",
          width: "33%",
          sortable: false,
        },
        {
          text: "admin.day",
          align: "center",
          value: "status",
          width: "33%",
          sortable: false,
        },     
      ],
      disabledsave: false,
        }
     },
     computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
      "dataCheckPackage",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    pageCount: {
      get() {
        let l = this.listcount;
        let s = this.size;
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      },
    },
    paginatedData() {
      const start = (this.page - 1) * this.size;
      const end = start + this.size;
      return this.data.history_log.slice(start, start + this.size);
    },
    headerTable() {
      return (
        "color:" + this.color.theme + ";" + "font-weight: 600; font-size:15px;"
      );
    },
    headerPage() {
      return (
        "color:" + this.color.theme + ";" + "font-size: 18px; font-weight: 600;"
      );
    },
    colorVapp() {
      return "background:" + this.color.BG + ";";
    },
    colorProgress() {
      return "color:" + this.color.theme + ";";
    },
    //สีพื้นหลังและขอบของ card ที่แสดงรายการ
    displayMobile() {
      if (this.$vuetify.theme.dark){
        return "background: #464646; border: 1px solid #F2F2F2; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); border-radius: 10px;"
      } else {
        return "background: #FFFFFF; border: 1px solid #F2F2F2; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); border-radius: 10px;"
      }
    },
    // สีพื้นหลังของ list ที่แสดงรายการไฟล์
    styleDisplayMobile() {
      if (this.$vuetify.theme.dark) {
        return "background: #464646;"
      } else {
        return "background: #FFFFFF;"
      }
    },
    // สีพื้นหลังและเส้นขอบล่างของ list item ของแต่ละอัน
    displayMobileForListItem() {
      if (this.$vuetify.theme.dark) {
        return "background: #464646; border-bottom: 1px solid #F3F5F7;"
      } else {
        return "background: #FFFFFF; border-bottom: 1px solid #F3F5F7;"
      }
    },
    maxWidthOnMobile() {
      if(this.resolutionScreen >= 400) {
        return 600
      } else {
        // return 346
        return 400
      }
    },
    shower: {
      get() {
        if (this.show === true) {
          console.log("show",this.show);
          if(this.data.current_password_expired === '0' || this.data.current_password_expired === 0){
            this.day_expired = '';
          }else{
          this.day_expired = this.data.current_password_expired;
          }
        }
        return this.show
      }
    },
    dayExpiredErrors() {
      let errors = [];
      console.log("this.day_expired",this.day_expired);
      if (this.day_expired > 366) {
        errors.push(this.$t("admin.maxenterdatenumber"));
        this.disabledsave = true;
        return errors;
      } else {
        this.disabledsave = false;
        return errors;
      }
    },
},
methods: {
  formatdatetime(_datetime) {
      if (
        _datetime === "" ||
        _datetime === "-" ||
        _datetime === undefined ||
        _datetime === null
      ) {
        return "-";
      } else {
        let dateyear =
          _datetime.split("")[0] +
          _datetime.split("")[1] +
          _datetime.split("")[2] +
          _datetime.split("")[3];
        let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
        let dateday = _datetime.split("")[6] + _datetime.split("")[7];
        let hour = _datetime.split("")[8] + _datetime.split("")[9];
        let minute = _datetime.split("")[10] + _datetime.split("")[11];
        let second = _datetime.split("")[12] + _datetime.split("")[13];

        return (
          dateday +
          "/" +
          datemonth +
          "/" +
          dateyear +
          " " +
          hour +
          ":" +
          minute 
          // +
          // ":" +
          // second
        );
      }
    },
    async setting_update_password(){
      let payload = {
        business_id: this.dataAccountActive.business_info.business_id,
        password_expired: this.day_expired === '' || this.day_expired === null ? '0' : this.day_expired,
        user_id: this.dataUsername,

      };
      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post( process.env.VUE_APP_SERVICE_AUTHORIZE_API + "/api/business/update/password_expired", payload, {
          headers: { Authorization: auth.code },}
        )
        .then((res) => {
          if (res.data.status == "OK") {
             Toast.fire({
              icon: "success",
              title: this.$t("admintoast.settingsuccess"),
            });
            this.$emit('close');
          } 
          else {
            Toast.fire({
              icon: "error",
              title: this.$t("toast.cannotconnectonebox"),
            });
          }
        })
        .catch((err) => {
          console.log(err);
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
        });
    },
    // async get_log_password_expired(){
    //   // console.log("เข้า get_log_password_expired");
    //   let payload = {
    //     business_id: this.dataAccountActive.business_info.business_id,
    //   };
    //   console.log("payload", payload);
    //   let auth = await gbfGenerate.generateToken();
    //   await this.axios
    //     .post( process.env.VUE_APP_SERVICE_AUTHORIZE_API + "/api/business/get/password_expired", payload, {
    //       headers: { Authorization: auth.code },}
    //     )
    //     .then((res) => {
    //       if (res.data.status == "OK") {
    //         console.log("ok");
    //       } 
    //       else {
    //         Toast.fire({
    //           icon: "error",
    //           title: this.$t("toast.cannotconnectonebox"),
    //         });
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       Toast.fire({
    //         icon: "error",
    //         title: this.$t("toast.cannotconnectonebox"),
    //       });
    //     });
    // },
    clear(){
      this.day_expired = ''
    },
},
}
</script>

<style>

</style>